














import { Component, Vue } from 'vue-property-decorator';
import ExplorerContentTitlebar from '@/pages/explorer/components/ExplorerContentTitlebar.vue';

@Component({
  components: {
    ExplorerContentTitlebar,
  },
})
export default class ImportPage extends Vue {}
